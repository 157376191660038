import React, { useEffect, useState, useContext, useMemo } from "react";
import "./SvgAdminPanel.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { parse } from "jsonc-parser";
import { DataGrid } from "@mui/x-data-grid";
import { Tabs, Tab } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoIcon from "@mui/icons-material/Photo";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import { alignProperty } from "@mui/material/styles/cssUtils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SvgAdminPanel = () => {
  const [text, setText] = useState("");
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [textArea, settextArea] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [textHeight1, setTextHeight1] = useState("");
  const [textHeight2, setTextHeight2] = useState("");
  const [textHeight3, setTextHeight3] = useState("");
  const [textHeight4, setTextHeight4] = useState("");
  const [rectangleX, setRectangleX] = useState("");
  const [rectangleY, setRectangleY] = useState("");
  const [rectangleWidth, setRectangleWidth] = useState("");
  const [rectangleHeight, setRectangleHeight] = useState("");
  const [rectangleX2, setRectangleX2] = useState("");
  const [rectangleY2, setRectangleY2] = useState("");
  const [rectangleWidth2, setRectangleWidth2] = useState("");
  const [rectangleHeight2, setRectangleHeight2] = useState("");
  const [rectangleX3, setRectangleX3] = useState("");
  const [rectangleY3, setRectangleY3] = useState("");
  const [rectangleWidth3, setRectangleWidth3] = useState("");
  const [rectangleHeight3, setRectangleHeight3] = useState("");
  const [rectangleX4, setRectangleX4] = useState("");
  const [rectangleY4, setRectangleY4] = useState("");
  const [rectangleWidth4, setRectangleWidth4] = useState("");
  const [rectangleHeight4, setRectangleHeight4] = useState("");
  const [fontFamily, setfontFamily] = useState("");
  const [fontFamily2, setfontFamily2] = useState("");
  const [fontFamily3, setfontFamily3] = useState("");
  const [fontFamily4, setfontFamily4] = useState("");
  const [textCurve1, setTextCurve1] = useState("");
  const [textCurve2, setTextCurve2] = useState("");
  const [textCurve3, setTextCurve3] = useState("");
  const [textCurve4, setTextCurve4] = useState("");
  const [text1Visible, setText1Visible] = useState(false);
  const [textAreaVisible, settextAreaVisible] = useState(false);
  const [text2Visible, setText2Visible] = useState(false);
  const [text3Visible, setText3Visible] = useState(false);
  const [text4Visible, setText4Visible] = useState(false);
  const [rotationAngle1, setRotationAngle1] = useState(0);
  const [rotationAngle2, setRotationAngle2] = useState(0);
  const [rotationAngle3, setRotationAngle3] = useState(0);
  const [rotationAngle4, setRotationAngle4] = useState(0);
  const [selectedAligment, setselectedAligment] = useState("left");
  const [fontStatus, setFontStatus] = useState("false");
  const [designType, setDesignType] = useState("");
  const [lineCounts, setLineCounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("processed-image.svg");
  const [lineHeight, setLineHeight] = useState("55");
  const PathContext = React.createContext([]);
  const [shopData, setShopData] = useState([]);
  const [presentPath, setPresentPath] = useState("");
  const [rows, setRows] = useState([]);
  const [parentID, setParentID] = useState("");
  const [childID, setChildID] = useState("");
  const [nodes, setNodes] = useState([]);
  const [checked, setChecked] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [selectedPaths, setSelectedPaths] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [showFiles, setShowFiles] = useState(1);
  const [dimensions, setDimensions] = useState({ width: "", height: "" });
  const [treeImageUrl, setTreeImageUrl] = React.useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fontList, setFontList] = useState([]);
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";
  let imagePathRoot = status === "0" ? "ImagesTest/Shops" : "Images/Shops";
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false); // Şifre kontrol durumu
  const [password, setPassword] = useState("");
  const correctPassword = "m6mf9d3m"; 
  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true); // Şifre doğruysa sayfa gösterilecek
    } else {
      alert("Wrong password");
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const response = await axios.get(path + "/api/shopData"); // Fetch from the backend API
        console.log(response.data);
        const parsedData = response.data;
        setShopData(parsedData); // Set the fetched data to shopData state
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchShopData();
  }, []);

  useEffect(() => {
    const fetchFontList = async () => {
      try {
        const response = await axios.get(path + "/api/fontList"); // Fetch from the backend API
        const parsedData = response.data;
        setFontList(parsedData);
      } catch (error) {
        console.error("Error fetching shop dataa:", error);
      }
    };

    fetchFontList();
  }, []);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await fetch(
          path + `/api/folders?showFiles=${showFiles}`
        );
        const data = await response.json();
        const processedData = processData(data);
        setNodes(processedData);
      } catch (error) {
        console.error("Error fetching folders:", error);
      }
    };

    fetchFolders();
  }, [showFiles, refetchTrigger]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setShowFiles(1);
    } else if (newValue === 1) {
      setShowFiles(0);
    }
  };

  const processData = (data) => {
    return data.map((item) => ({
      id: item.key,
      label: item.label,
      children: item.children ? processData(item.children) : [],
    }));
  };
  const handleToggleShowFiles = () => {
    setShowFiles((prevShowFiles) => (prevShowFiles === 1 ? 0 : 1));
  };

  const handleCheckboxChange = (nodeId, nodeLabel, parentPath = "") => {
    // Get the current checked state of the checkbox
    const isCurrentlyChecked = checked[nodeId] || false;
    const newChecked = !isCurrentlyChecked;

    // Update the checked state
    setChecked((prevChecked) => ({
      ...prevChecked,
      [nodeId]: newChecked,
    }));

    // Construct the full path
    const fullPath = parentPath ? `${parentPath}/${nodeLabel}` : nodeLabel;

    // Update the selectedPaths state based on the new checked state
    setSelectedPaths((prevSelectedPaths) => {
      if (newChecked) {
        // Checkbox is being checked, add the path
        return [...prevSelectedPaths, fullPath];
      } else {
        // Checkbox is being unchecked, remove the path
        return prevSelectedPaths.filter((path) => path !== fullPath);
      }
    });

    if (
      newChecked &&
      (fullPath.endsWith(".svg") || fullPath.endsWith(".png"))
    ) {
      setImageUrl(imagePathRoot + fullPath);
      console.log(imagePathRoot + fullPath);
      setTreeImageUrl(imagePathRoot + fullPath);
    }
  };

  const handleNodeToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };
  const renderTree = (nodes, parentPath = "") => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={!!checked[nodes.id]}
              onChange={() =>
                handleCheckboxChange(nodes.id, nodes.label, parentPath)
              }
              onClick={(e) => e.stopPropagation()} // Prevent expanding/collapsing on checkbox click
            />
          }
          label={nodes.label}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) =>
            renderTree(node, `${parentPath}/${nodes.label}`)
          )
        : null}
    </TreeItem>
  );

  const handleClose = () => {
    setOpen(false);
  };

  const CustomButton = styled(Button)({
    borderColor: "black",
    color: "black",
    border: "2px solid #1A1A1A",
    borderRadius: "15px",
    boxSizing: "border-box",
    fontFamily:
      'Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: "12px",
    fontWeight: 500,
    minHeight: "20px",
    padding: "16px 24px",
    transition: "all 300ms cubic-bezier(.23, 1, 0.32, 1)",
    width: "20%",
    marginBottom: "6%",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1A1A1A",
      borderColor: "rgba(121, 147, 81)",
      boxShadow: "rgba(0, 0, 0, 0.25) 0 8px 15px",
      transform: "translateY(-2px)",
    },
    "&:active": {
      boxShadow: "none",
      transform: "translateY(0)",
    },
    "&:disabled": {
      pointerEvents: "none",
    },
  });

  const [svgContent, setSvgContent] = useState("");
  const [svgExample, setSvgExample] = useState("");

  useEffect(() => {
    setSvgExample("");
    fetch(imageUrl)
      .then((response) => response.text())
      .then((data) => setSvgContent(data));
  }, [imageUrl]);

  const rects = [
    {
      x: rectangleX,
      y: rectangleY,
      width: rectangleWidth,
      height: rectangleHeight,
      text1: text || textArea,
      textHeight1: textHeight1,
      fontFamily: fontFamily,
      textCurve: textCurve1,
      rotationAngle: rotationAngle1,
    },
    {
      x: rectangleX2,
      y: rectangleY2,
      width: rectangleWidth2,
      height: rectangleHeight2,
      text2: text1,
      textHeight2: textHeight2,
      fontFamily: fontFamily2 || fontFamily,
      textCurve: textCurve2,
      rotationAngle: rotationAngle2,
    },
    {
      x: rectangleX3,
      y: rectangleY3,
      width: rectangleWidth3,
      height: rectangleHeight3,
      text3: text2,
      textHeight3: textHeight3,
      fontFamily: fontFamily3 || fontFamily,
      textCurve: textCurve3,
      rotationAngle: rotationAngle3,
    },
    {
      x: rectangleX4,
      y: rectangleY4,
      width: rectangleWidth4,
      height: rectangleHeight4,
      text4: text3,
      textHeight4: textHeight4,
      fontFamily: fontFamily4 || fontFamily,
      textCurve: textCurve4,
      rotationAngle: rotationAngle4,
    },
  ];

  const downloadSVG = async () => {
    try {
      const processedSVG = await processSVG(false); // processSVG fonksiyonunuza göre özelleştirin
      const blob = await processedSVG.blob();

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      handleClose(); // İndirme tamamlandıktan sonra dialogu kapat
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const trySVG = async (updatedRects) => {
    try {
      setSvgExample("");

      const processedSVG = await processSVG(true, updatedRects);
      const data = await processedSVG.text(); // Use .text() instead of .blob()

      setSvgExample(data);
    } catch (error) {
      console.error("Error fetching SVG:", error);
    }
  };

  const processSVG = async (test, updatedRects) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, "image/svg+xml");
    const textElement = doc.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );
    textElement.textContent = text;
    doc.documentElement.appendChild(textElement);

    const serializedSVG = new XMLSerializer().serializeToString(doc);

    return fetch(path + '/process-svg', {////////////////////////
    // return fetch("http://localhost:3003/process-svg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: text,
        svg: serializedSVG,
        rects: updatedRects,
        fontFamily: fontFamily,
        fontStatus: fontStatus,
        alignment: selectedAligment,
        designType: designType,
        test: test,
        lineHeight: lineHeight,
        rotationAngle: rotationAngle1,
        dimensions: dimensions,
        adminPanel: true,
      }),
    });
  };

  const RecursiveAccordion = ({ data, parentPath = [] }) => {
    //console.log(data);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [expanded, setExpanded] = useState({});
    const currentPath = useContext(PathContext);

    const newPath = data.name ? [...currentPath, data.name] : currentPath;
    const fullPath = newPath.join(".");

    useEffect(() => {
      setExpanded((prev) => ({ ...prev, [fullPath]: data.autoOpen || false }));
    }, [fullPath, data.autoOpen]);

    const handleChange = (event, isExpanded) => {
      setExpanded((prev) => ({ ...prev, [fullPath]: isExpanded }));
    };

    const handleMouseEnter = (name) => {
      setHoveredItem(name);
    };

    const handleMouseLeave = () => {
      setHoveredItem(null);
    };

    const changeImageUrl = (
      newImageUrl,
      height,
      width,
      x,
      y,
      height2,
      width2,
      x2,
      y2,
      height3,
      width3,
      x3,
      y3,
      textHeight1,
      textHeight2,
      textHeight3,
      fontFamily,
      text1Visible,
      text2Visible,
      text3Visible,
      textAreaVisible,
      designType,
      rotationAngle1,
      rotationAngle2,
      rotationAngle3,
      rotationAngle4,
      fontFamily2,
      textHeight4,
      height4,
      width4,
      x4,
      y4,
      text4Visible,
      fontFamily3,
      fontFamily4,
      textCurve1,
      textCurve2,
      textCurve3,
      textCurve4,
      id
    ) => {
      setText("");
      setText1("");
      setText2("");
      setText3("");
      settextArea("");
      setImageUrl(newImageUrl);
      setRectangleX(x);
      setRectangleY(y);
      setRectangleWidth(width);
      setRectangleHeight(height);
      setRectangleX2(x2);
      setRectangleY2(y2);
      setRectangleWidth2(width2);
      setRectangleHeight2(height2);
      setRectangleX3(x3);
      setRectangleY3(y3);
      setRectangleWidth3(width3);
      setRectangleHeight3(height3);
      setTextHeight1(textHeight1);
      setTextHeight2(textHeight2);
      setTextHeight3(textHeight3);
      setfontFamily(fontFamily);
      setfontFamily2(fontFamily2);
      setText1Visible(text1Visible);
      setText2Visible(text2Visible);
      setText3Visible(text3Visible);
      settextAreaVisible(textAreaVisible);
      setDesignType(designType);
      setRotationAngle1(rotationAngle1);
      setRotationAngle2(rotationAngle2);
      setRotationAngle3(rotationAngle3);
      setRotationAngle4(rotationAngle4);
      setTextHeight4(textHeight4);
      setRectangleX4(x4);
      setRectangleY4(y4);
      setRectangleWidth4(width4);
      setRectangleHeight4(height4);
      setText4Visible(text4Visible);
      setfontFamily3(fontFamily3);
      setfontFamily4(fontFamily4);
      setTextCurve1(textCurve1);
      setTextCurve2(textCurve2);
      setTextCurve3(textCurve3);
      setTextCurve4(textCurve4);
      setChildID(id);
      setPresentPath(newPath.join(" > "));
    };

    return (
      <PathContext.Provider value={newPath}>
        <Accordion
          //expanded={data.autoOpen}
          onChange={handleChange}
          style={{
            background: data.background,
            borderRadius: data.borderRadius,
            overflow: "hidden",
            position: "relative",
          }}
          onMouseEnter={() => handleMouseEnter(data.name)}
          onMouseLeave={handleMouseLeave}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{data.name}</Typography>
            {data.url && (
              <img
                src={
                  hoveredItem === data.name && data.hoverImageUrl
                    ? data.hoverImageUrl
                    : data.url
                }
                alt="icon"
                style={{ width: 50, height: 50 }}
              />
            )}
          </AccordionSummary>
          <AccordionDetails
            style={{
              maxHeight: data.maxHeight,
              overflow: "auto",
              background: data.background,
            }}
          >
            {data.details.map((detail, index) => {
              if (detail.name === "SubShop1Button") {
                return (
                  <div key={index}>
                    {/* Render your buttons here */}
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                    >
                      <Button
                        onClick={() =>

                          changeImageUrl(
                            detail.url,
                            detail.height1,
                            detail.width1,
                            detail.x1,
                            detail.y1,
                            detail.height2,
                            detail.width2,
                            detail.x2,
                            detail.y2,
                            detail.height3,
                            detail.width3,
                            detail.x3,
                            detail.y3,
                            detail.textHeight1,
                            detail.textHeight2,
                            detail.textHeight3,
                            detail.fontFamily,
                            detail.text1Visible,
                            detail.text2Visible,
                            detail.text3Visible,
                            detail.textAreaVisible,
                            detail.designType,
                            detail.rotationAngle1,
                            detail.rotationAngle2,
                            detail.rotationAngle3,
                            detail.rotationAngle4,
                            detail.fontFamily2,
                            detail.textHeight4,
                            detail.height4,
                            detail.width4,
                            detail.x4,
                            detail.y4,
                            detail.text4Visible,
                            detail.fontFamily3,
                            detail.fontFamily4,
                            detail.textCurve1,
                            detail.textCurve2,
                            detail.textCurve3,
                            detail.textCurve4,
                            detail.id
                          )
                        }
                        variant="contained"
                        style={{
                          backgroundColor: "#f5f5dc",
                          color: "#000",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px 20px",
                          borderRadius: "8px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          textTransform: "none",
                          width: "-webkit-fill-available",
                        }}
                      >
                        {detail.url && !detail.url.includes("EmptySvg") && (
                          <img
                            src={detail.url}
                            alt="icon"
                            style={{
                              width: 50,
                              height: 50,
                              marginRight: "8px",
                              borderRadius: "4px",
                            }}
                          />
                        )}
                        {detail.buttonName}
                      </Button>
                    </div>
                  </div>
                );
              } else if (detail.subShops) {
                return (
                  <RecursiveAccordion
                    key={index}
                    data={{
                      name: detail.name,
                      details: detail.subShops,
                      maxHeight: detail.maxHeight,
                      autoOpen: detail.autoOpen,
                      buttonText: detail.buttonText,
                      background: detail.background,
                      url: detail.url,
                      hoverImageUrl: detail.hoverImageUrl,
                    }}
                  />
                );
              } else {
                return (
                  <div key={index}>
                    <div>{detail.name}</div>
                    {/* Render other content if needed */}
                  </div>
                );
              }
            })}
          </AccordionDetails>
        </Accordion>
      </PathContext.Provider>
    );
  };

  useEffect(() => {
    if (rects.length > 0) {
      setFilledRows((prevRows) => {
        if (prevRows.length === 0) {
          fillTable();
        }
        return prevRows;
      });
    }
  }, [rectangleX, rectangleX2, rectangleX3, rectangleX4]);

  const processRowUpdate = async (newRow, oldRow) => {
    try {
      // Güncellenen satırı backend'e gönder
      const response = await axios.put(
        path + `/api/trademarks/${newRow.id}`,
        newRow
      );
      return response.data; // API'den dönen güncellenmiş veriyi geri döndürüyoruz
    } catch (error) {
      console.error("Error updating data:", error);
      return oldRow; // Hata durumunda eski veriyi geri döndürüyoruz
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChanges = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setShowFiles(1);
      setSelectedPaths([]);
      setImageUrl("");
    } else if (newValue === 1) {
      setShowFiles(0);
      setSelectedPaths([]);
      setImageUrl("");
    }
  };

  const handleFileChange = (event) => {
    event.preventDefault(); // Prevent default behavior
    const files = event.target.files;
    if (files && files.length > 0) {
      setUploadedFile(files[0]); // Only store one file
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null); // Clear the uploaded file
  };

  const handleFileUpload = async () => {
    if (!uploadedFile) {
      setSnackbar({
        open: true,
        severity: "warning",
        message: "Lütfen bir dosya seçin",
      });
      return;
    }

    const formData = new FormData();
    formData.append("folderPath", selectedPaths[selectedPaths.length - 1]);
    formData.append("file", uploadedFile);

    try {
      const response = await fetch(path + "/api/UploadImage", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          severity: "success",
          message: "Dosya başarıyla yüklendi",
        });
        console.log("File uploaded successfully");
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          message: "Dosya yükleme başarısız oldu",
        });
        console.error("File upload failed");
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Dosya yüklenirken bir hata oluştu: " + error.message,
      });
      console.error("Error uploading file:", error);
    }
  };

  const handleFolderSubmit = async (event) => {
    event.preventDefault();
    const folderName = event.target.folderName.value;


    if (!folderName.trim()) {
      return;
    }

    const formData = new FormData();
    formData.append("folderName", folderName);
    formData.append("folderPath", selectedPaths[selectedPaths.length - 1]);

    try {
      const response = await fetch(path + "/api/CreateFolder", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setRefetchTrigger((prev) => prev + 1); // Trigger refetch
      } else {
        const errorData = await response.text();
        throw new Error(errorData);
      }
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };
  const [checkedRows, setCheckedRows] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const responseColumns = useMemo(
    () => [
      {
        field: "selection",
        headerName: "",
        width: 50,
        sortable: false,
        renderCell: (params) => {
          return (
            <Checkbox
              checked={selectedRows.includes(params.row.id)}
              onChange={(event) => {
                const isChecked = event.target.checked;

                // Toggle selection for this specific row
                setSelectedRows((prev) => (isChecked ? [params.row.id] : []));

                // Update checkbox state
                setCheckedRows((prev) => {
                  const newChecked = {};
                  if (isChecked) {
                    newChecked[params.row.id] = true;
                  }
                  return newChecked;
                });
              }}
            />
          );
        },
      },
      {
        field: "Height",
        headerName: "Height",
        width: 60,
        editable: true,
        type: "number",
      },
      {
        field: "Width",
        headerName: "Width",
        width: 60,
        editable: true,
        type: "number",
      },
      {
        field: "X",
        headerName: "X",
        width: 50,
        editable: true,
        type: "number",
      },
      {
        field: "Y",
        headerName: "Y",
        width: 50,
        editable: true,
        type: "number",
      },
      {
        field: "TextHeight",
        headerName: "Text Height",
        width: 80,
        editable: true,
        type: "number",
      },
      {
        field: "Font",
        headerName: "Font",
        width: 130,
        editable: true,
        type: "singleSelect",
        valueOptions: fontList.map((font) => font.name), // This creates a dropdown with your font list
      },
      {
        field: "Rotation",
        headerName: "Rotation",
        width: 75,
        editable: true,
        type: "number",
      },
      {
        field: "Curve",
        headerName: "Curve",
        width: 75,
        editable: true,
        type: "number",
      },
      { field: "Text", headerName: "Deneme Text", width: 75, editable: true },

      // Add a new column for actions
      {
        field: "actions",
        headerName: "Sil", // You can leave the header name empty
        width: 50,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation();
            const id = params.id;
            handleDeleteRow(id);
          };

          return (
            <IconButton onClick={onClick}>
              <DeleteIcon color="error" />
            </IconButton>
          );
        },
      },
    ],
    [fontList, selectedRows]
  );

  const [filledRows, setFilledRows] = React.useState([]);
  const [rowsAdd, setRowsAdd] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);

  const fillTable = () => {
    const filledRects = rects.filter(
      (rect) => rect && rect.height != null && rect.height !== ""
    );
  
    // Generate a single timestamp to ensure unique IDs
    const timestamp = Date.now();
    const dummy = filledRects
      .filter((rect) => rect.height !== 0 && rect.width !== 0) // height ve width kontrolü
      .map((rect, index) => {
        if (!rect) return null;
  
        return {
          id: `filled_${timestamp}_${index}`, // Benzersiz ID
          Height: rect.height || 0,
          Width: rect.width || 0,
          X: rect.x || 0,
          Y: rect.y || 0,
          TextHeight: rect.textHeight1 || 0,
          Font: rect.fontFamily || "",
          Rotation: rect.rotationAngle || 0,
          Curve: rect.textCurve || 0,
          Text: "",
        };
      });
  
    // Var olan satırları kontrol edip, sadece yeni olanları ekliyoruz
    setFilledRows((prevRows) => {
      const existingIds = new Set(prevRows.map((row) => row.id));
      const newRows = dummy.filter((row) => !existingIds.has(row.id));
      return [...prevRows, ...newRows];
    });
  };

  const addEmptyRow = () => {
    const newRow = {
      id: `new_${Date.now()}`, // Benzersiz ID
      Height: 0,
      Width: 0,
      X: 0,
      Y: 0,
      TextHeight: 0,
      Font: "Times.ttf",
      Rotation: 0,
      Curve: 0,
      Text: "",
    };
    setRowsAdd((prev) => [...prev, newRow]);
  };

  const handleDeleteRow = (id) => {
    setRowsAdd((prevRows) => prevRows.filter((row) => row.id !== id));
  };
  const [updatedRects, setUpdatedRects] = React.useState(null);
  const [updateKey, setUpdateKey] = React.useState(0);

  const processAllRows = () => {
    try {
      const allRows = [
        ...new Map(
          [...filledRows, ...rowsAdd].map((row) => [row.id, row])
        ).values(),
      ];

      const newRects = allRows.map((row, index) => ({
        x: row.X,
        y: row.Y,
        width: row.Width,
        height: row.Height,
        [`text${index + 1}`]: row.Text,
        textHeight: row.TextHeight,
        fontFamily: row.Font || "defaultFont",
        textCurve: row.Curve,
        rotationAngle: row.Rotation,
      }));

      setUpdatedRects(newRects);
      // Her çağrıda update key'i artır
      setUpdateKey((prev) => prev + 1);
    } catch (error) {
      console.error("Error in processAllRows:", error);
    }
  };

  // UseEffect'i modifiye et
  React.useEffect(() => {
    if (updatedRects) {
      trySVG(updatedRects);
    }
  }, [updatedRects, updateKey]);

  const handleButtonClick = (event) => {
    const buttonId = event.currentTarget.id;
    if (selectedRows.length > 0) {
      const selectedRowId = selectedRows[0]; // Assuming single selection
      let updatedRow;

      // Check if the selected row is in filledRows
      if (filledRows.find((row) => row.id === selectedRowId)) {
        setFilledRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === selectedRowId) {
              let newX = row.X;
              let newY = row.Y;
              if (buttonId === "up") {
                newY -= 5;
              } else if (buttonId === "down") {
                newY += 5;
              } else if (buttonId === "left") {
                newX -= 5;
              } else if (buttonId === "right") {
                newX += 5;
              }

              updatedRow = { ...row, X: newX, Y: newY };
              return updatedRow;
            }
            return row;
          })
        );
      } else if (rowsAdd.find((row) => row.id === selectedRowId)) {
        setRowsAdd((prevRows) =>
          prevRows.map((row) => {
            if (row.id === selectedRowId) {
              let newX = row.X;
              let newY = row.Y;

              if (buttonId === "up") {
                newY -= 5;
              } else if (buttonId === "down") {
                newY += 5;
              } else if (buttonId === "left") {
                newX -= 5;
              } else if (buttonId === "right") {
                newX += 5;
              }

              updatedRow = { ...row, X: newX, Y: newY };
              return updatedRow;
            }
            return row;
          })
        );
      }

      // Call your SVG update function if needed
      processAllRows();
    } else {
      console.log("No row selected");
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.name;
    const folderName = event.target.DesignName.value;
    const buttonName = event.target.ButtonName.value;

    if (buttonClicked === "add") {
      addDesignToServer(folderName, buttonName);
    } else if (buttonClicked === "update") {
      updateDesignToServer(folderName, buttonName);
    }
  };
  const addDesignToServer = async (folderName, buttonName) => {
    try {
      let tableName = status === "0" ? "NodesTest" : "Nodes";
      const response = await axios.post(path + "/api/addNode", {
        tableName: tableName,
        rects: updatedRects,
        path: selectedPaths[selectedPaths.length - 1],
        designName: folderName,
        buttonName: buttonName,
      });

      console.log("Backend Yanıtı:", response);
      alert("Veri başarıyla eklendi!");
    } catch (error) {
      console.error("Hata Ayıklama - addDesignToServer:", {
        message: error.message,
        stack: error.stack,
        config: error.config,
        response: error.response ? error.response.data : null,
      });
      alert("Bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const updateDesignToServer = async (folderName, buttonName) => {
    processAllRows();
    console.log("childID", childID);
    try {
      let tableName = status === "0" ? "NodesTest" : "Nodes";
      const response = await axios.post(path + "/api/updateNode", {
        tableName: tableName,
        rects: updatedRects,
        path:
          selectedPaths[selectedPaths.length - 1] !== undefined
            ? selectedPaths[selectedPaths.length - 1]
            : "",
        designName: folderName,
        buttonName: buttonName,
        childID: childID,
      });

      console.log("Veri başarıyla gönderildi:", response);
      alert("Veri başarıyla Güncellendi!");
    } catch (error) {
      console.error("Veri gönderimi sırasında bir hata oluştu:", error);
      alert("Bir hata oluştu. Lütfen tekrar deneyin:.", error);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="password-screen">
        <div className="password-popup">
          <h3>Enter Password</h3>
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            onClick={handlePasswordSubmit}
            variant="contained"
            style={{ marginTop: 10 }}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className="outer-containerSC">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Adjust position as needed
      ></Snackbar>
      <div className="containerSC">
        <div className="layout-panelSC">
          <div>
            {shopData.map((shop, index) => (
              <RecursiveAccordion key={index} data={shop} />
            ))}
          </div>
        </div>

        <div className="left-panelSC">
          <Box
            sx={{
              width: "100%",
              maxHeight: "100vh",
              overflowY: "auto",
              overflowX: "hidden",
              "@media (max-width: 1200px)": {
                maxHeight: "90vh",
              },
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChanges}
                aria-label="basic tabs example"
              >
                <Tab label="Dizayn Tanımlama" {...a11yProps(0)} />
                <Tab label="Dosya Tanımlama" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <form onSubmit={handleSubmit}>
                <div>
                  <h2>Tanımlama Paneli</h2>
                </div>
                <Divider style={{ margin: "20px 0" }} />
                <h3 style={{ color: "#705C53" }}> {presentPath}</h3>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <div>
                    <TreeView
                      expanded={expanded}
                      onNodeToggle={handleNodeToggle}
                      defaultCollapseIcon={<ExpandMore />}
                      defaultExpandIcon={<ChevronRight />}
                      sx={{
                        height: "auto",
                        flexGrow: 1,
                        maxHeight: "300px",
                        overflowY: "auto",
                        width: 400,
                        marginRight: 10,
                      }} // Set maxHeight as desired
                    >
                      {nodes.map((node) => renderTree(node))}
                    </TreeView>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h3>Selected Paths:</h3>
                      <ul
                        style={{
                          marginLeft: "10px",
                          listStyleType: "none",
                          padding: 0,
                        }}
                      >
                        {selectedPaths.length > 0 && (
                          <li key={selectedPaths[selectedPaths.length - 1]}>
                            {selectedPaths[selectedPaths.length - 1]}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>

                  <div>
                    <TextField
                      id="DesignName"
                      name="DesignName" // FormData için name özelliği gerekli
                      label="Dizayn Adı"
                      variant="standard"
                      style={{ marginBottom: "20px", width: "80%" }}
                    />
                    <TextField
                      id="ButtonName"
                      name="ButtonName" // FormData için name özelliği gerekli
                      label="Buton İsmi"
                      variant="standard"
                      style={{ marginBottom: "20px", width: "80%" }}
                    />
                  </div>
                </div>

                <Box sx={{ height: "30%", width: "95%" }}>
                  <DataGrid
                    rows={[
                      ...new Map(
                        [...filledRows, ...rowsAdd].map((row) => [row.id, row])
                      ).values(),
                    ]}
                    columns={responseColumns}
                    processRowUpdate={(newRow) => {
                      if (filledRows.some((row) => row.id === newRow.id)) {
                        setFilledRows((prevRows) =>
                          prevRows.map((row) =>
                            row.id === newRow.id ? newRow : row
                          )
                        );
                      } else {
                        setRowsAdd((prevRows) =>
                          prevRows.map((row) =>
                            row.id === newRow.id ? newRow : row
                          )
                        );
                      }
                      return newRow;
                    }}
                    rowSelectionModel={selectedRows}
                    onRowClick={(params) => {
                      console.log("Row clicked:", params.row);
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    hideFooter={true}
                  />
                  <Button variant="contained" onClick={addEmptyRow}>
                    Boş Satır Ekle
                  </Button>
                </Box>
                <div style={{ marginTop: 20 }}>
                  <CustomButton
                    style={{ marginRight: 10 }}
                    type="submit"
                    name="add"
                  >
                    Ekle
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    name="update"
                    style={{ marginRight: 10 }}
                  >
                    Güncelle
                  </CustomButton>
                  <CustomButton type="button" onClick={processAllRows}>
                    Dene
                  </CustomButton>
                </div>
              </form>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <TreeView
                expanded={expanded}
                onNodeToggle={handleNodeToggle}
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                sx={{
                  height: "auto",
                  flexGrow: 1,
                  maxHeight: "400px",
                  overflowY: "auto",
                }} // Set maxHeight as desired
              >
                {nodes.map((node) => renderTree(node))}
              </TreeView>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3>Selected Paths:</h3>
                <ul
                  style={{
                    marginLeft: "10px",
                    listStyleType: "none",
                    padding: 0,
                  }}
                >
                  {selectedPaths.length > 0 && (
                    <li key={selectedPaths[selectedPaths.length - 1]}>
                      {selectedPaths[selectedPaths.length - 1]}
                    </li>
                  )}
                </ul>
              </div>
              <Divider style={{ margin: "20px 0" }} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a>Boş Tamplate Yükle</a>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginRight: 20 }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    disabled={!!uploadedFile}
                  >
                    Tamplate Yükle
                    <input
                      type="file"
                      onChange={handleFileChange}
                      multiple={false}
                      hidden
                    />
                  </Button>

                  {uploadedFile && (
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 10,
                      }}
                    >
                      <PhotoIcon />
                      <span>{uploadedFile.name}</span>
                      <IconButton
                        onClick={handleRemoveFile}
                        size="small"
                        style={{ marginLeft: "5px" }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </div>
                  )}
                </div>

                <Button
                  variant="contained"
                  onClick={handleFileUpload}
                  disabled={!uploadedFile}
                >
                  Yükle
                </Button>
              </div>
              <Divider style={{ margin: "20px 0" }} />
              <form onSubmit={handleFolderSubmit}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <a>Yeni Klasör Oluştur</a>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TextField
                    id="folderName"
                    name="folderName"
                    label="Dosya İsmini Girin"
                    variant="standard"
                    style={{ marginBottom: "20px", width: "60%" }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button variant="contained" type="submit">
                    Klasör Oluştur{" "}
                  </Button>
                </div>
              </form>
              <div></div>
            </TabPanel>
          </Box>
        </div>

        <div className="right-panelSC">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px", // Sections arasında boşluk
              width: "100%",
            }}
          >
            {/* Alert Messages Section */}
            <div style={{ width: "100%", marginTop: 30 }}>
              {successMessage && (
                <Alert severity="success" onClose={() => setSuccessMessage("")}>
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert severity="error" onClose={() => setErrorMessage("")}>
                  {errorMessage}
                </Alert>
              )}
            </div>

            {/* SVG Content Section */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {svgExample === "" && svgContent === "" ? (
                <div>
                  <p>Lütfen Düzenlemek İstediğiniz Dizaynı Seçiniz.</p>
                </div>
              ) : (
                <>
                  {svgExample !== "" ? (
                    <>
                      <div dangerouslySetInnerHTML={{ __html: svgExample }} />
                      <svg
                        style={{
                          height: "500px",
                          width: "500px",
                        }}
                        dangerouslySetInnerHTML={{ __html: svgExample }}
                      />
                    </>
                  ) : (
                    <>
                      <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                      <svg
                        style={{
                          height: "500px",
                          width: "500px",
                        }}
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                      />
                    </>
                  )}
                </>
              )}
            </div>

            {/* Navigation Buttons Section */}
            <div style={{ width: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                style={{ marginBottom: "20px" }}
              >
                <Button
                  id={"up"}
                  onClick={handleButtonClick}
                  variant="outlined"
                  startIcon={<ArrowUpwardIcon />}
                  style={{ marginBottom: "10px" }}
                />
                <Box display="flex">
                  <Button
                    id={"left"}
                    onClick={handleButtonClick}
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginRight: "10px" }}
                  />
                  <Button
                    id={"right"}
                    onClick={handleButtonClick}
                    variant="outlined"
                    startIcon={<ArrowForwardIcon />}
                    style={{ marginLeft: "10px" }}
                  />
                </Box>
                <Button
                  id={"down"}
                  onClick={handleButtonClick}
                  variant="outlined"
                  startIcon={<ArrowDownwardIcon />}
                  style={{ marginTop: "10px" }}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Download SVG</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter a name for the file.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="File Name"
              type="text"
              fullWidth
              variant="outlined"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={downloadSVG} variant="contained" color="primary">
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default SvgAdminPanel;
